const AmaZonPay = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="28"
      viewBox="0 0 44 28"
      fill="none"
      {...props}
    >
      <path
        d="M40.5266 1.6582C41.5242 1.6582 42.3424 2.47645 42.3424 3.47399V24.3161C42.3424 25.3136 41.5242 26.1319 40.5266 26.1319H3.47399C2.47645 26.1319 1.6582 25.3136 1.6582 24.3161V3.47399C1.6582 2.47645 2.47645 1.6582 3.47399 1.6582H40.5266Z"
        stroke="#E9E9E9"
      />
      <path
        d="M29.2478 18.7923C27.2829 20.506 24.4345 21.4207 21.9821 21.4207C18.5431 21.4207 15.4469 19.9154 13.1056 17.4109C12.9204 17.2141 13.0848 16.9454 13.3059 17.0983C15.8336 18.8398 18.9588 19.8865 22.187 19.8865C24.3638 19.8865 26.7607 19.3539 28.9607 18.2469C29.2942 18.079 29.5744 18.504 29.2478 18.7911V18.7923ZM30.0653 17.6842C29.8152 17.3044 28.4049 17.5047 27.7727 17.5939C27.5793 17.6217 27.5492 17.4225 27.7229 17.2812C28.8461 16.3433 30.6894 16.6143 30.9048 16.9281C31.1213 17.243 30.8504 19.4291 29.7932 20.4747C29.6311 20.6345 29.4783 20.5488 29.5501 20.3358C29.7874 19.6364 30.3189 18.0663 30.0665 17.6842H30.0653Z"
        fill="#FF9900"
      />
      <path
        d="M25.2578 17.7055C24.5931 17.1289 24.475 16.8602 24.108 16.3102C23.0114 17.482 22.2345 17.8329 20.808 17.8329C19.1256 17.8329 17.8125 16.7468 17.8125 14.5699C17.8125 12.8713 18.6948 11.7134 19.9477 11.1483C21.0361 10.647 22.5529 10.5578 23.7108 10.4212V10.1491C23.7108 9.65119 23.749 9.06066 23.47 8.63224C23.2245 8.24666 22.7567 8.08803 22.3468 8.08803C21.5826 8.08803 20.8994 8.49677 20.735 9.34435C20.7003 9.53308 20.5683 9.71834 20.3877 9.72645L18.4459 9.50992C18.2838 9.47171 18.1031 9.33277 18.1483 9.06992C18.5952 6.6094 20.7223 5.86719 22.6259 5.86719C23.5985 5.86719 24.8722 6.13813 25.641 6.90929C26.616 7.85877 26.5233 9.13013 26.5233 10.5103V13.7733C26.5233 14.754 26.9112 15.1859 27.276 15.7139C27.4068 15.9038 27.4358 16.1308 27.2725 16.272C26.8638 16.6287 26.1378 17.291 25.7371 17.6615C25.6711 17.7165 25.5899 17.7501 25.5043 17.7579C25.4187 17.7658 25.3327 17.7475 25.2578 17.7055ZM23.3368 14.7726C23.7305 14.0477 23.7108 13.3669 23.7108 12.5517V12.0978C22.2634 12.0978 20.735 12.422 20.735 14.2052C20.735 15.1107 21.182 15.722 21.9508 15.722C22.5147 15.722 23.0184 15.3608 23.3368 14.7726Z"
        fill="#221F1F"
      />
    </svg>
  )
}

export default AmaZonPay
